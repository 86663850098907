import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { AnimatePresence } from "framer-motion";

import './styles.css';

import Home from './components/Home';
import About from './components/About';
import Toggle from './components/Atom/Toggle';
import { menuStatusContext,useStatus,menuContext, useDark } from './contexts/menuContext';

function App() {
  const ctx = useDark();
  const statusCtx = useStatus();
  
  return (
    <menuContext.Provider value={ctx}>
    <menuStatusContext.Provider value={statusCtx}>
    <Router>

      <div className="App">
      <Toggle/>
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path ="/" component={Home} />
          <Route exact path ="/home" component={Home} />
          <Route exact path ="/about" component={About} />
          {/* <Route exact path ="/contact" component={Contact} />  */}
          {/* <Route exact path ="/portfolio" component={Portfolio} /> */}
        </Switch> 
      </AnimatePresence>   
      </div>
    </Router>
    </menuStatusContext.Provider>
    </menuContext.Provider>
  );
}

export default App;




