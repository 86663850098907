import React ,{useState,useContext}from 'react'
import { motion ,useSpring,useTransform,AnimatePresence} from "framer-motion"
import styled from 'styled-components'
import { useInViewEffect} from 'react-hook-inview'
import { menuStatusContext } from '../contexts/menuContext';

import { ReactComponent as Logo} from './Atom/Data/IncLogo.svg';


 const ContainerCard = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 max-width:950px;
 width:calc(100% - 64px);
 margin-top:60px;
 margin-bottom:60px;
 margin-right:0px;
 margin-left:0px;
 `


const SvgContainer = styled(motion.div)`
  width:calc(100vw - 64px);
  max-width:950px;
 
  height:calc(300px);
  background-color:white; 
  padding:0;
  margin:0;
  display:flex;
  justify-content:center;
  align-items:center;

`

const SvgBox = styled(motion.svg)`
width: 120px;
height: 120px;
min-height: 160px;
object-fit: cover; 
margin-left:auto;
margin-right:auto;
`

const Title = styled.h1`
  font-family: transat-text, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.6rem;
  letter-spacing: 0.6rem;
  color: #222;
  border-style:none;
  outline:0px;
  margin-top:75px;
  `
  const TitleSub = styled.h1`
  font-family: 游ゴシック, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  color: #555;
  border-style:none;
  outline:0px;
  margin-top:10px;
  margin-bottom:75px;
  `

const JPName = styled.h1`
  color: #333;
  font-family: 游ゴシック, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 0.25rem;
  font-feature-settings: "palt";
  text-align:left;
  margin-top:2.5rem;
  @media screen and (min-width: 480px) {
    font-size: 1.5rem;
  }
`


const PARAGRAPH = styled.p`
  color: #333;
  font-family: 游ゴシック Medium, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 0.15rem;
  font-feature-settings: "palt";
  text-align:justify;
  margin-top:2.4rem;
  margin-bottom:2.4rem;
`

const Record = styled(motion.div)`
  width:calc(100vw - 32px);

  color: #222;
  font-family: 游ゴシック, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2rem;
  font-feature-settings: "palt";
  letter-spacing: 0.15rem;
  text-align:left;

  display:flex;
  flex-direction:column;
  justify-content: center;
  div{
        flex-grow:1;
        margin-top:0px;
        margin-bottom:0px;
        justify-content: center;
      } 
  div{    
      ul{
        text-align:left;
        list-style: none;
        display:flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding:0px;
        div{ 
            min-width:100%;
            flex-grow:1;
            li{ line-height:1.8rem;
            padding-top:0.2rem}
            li:nth-of-type(odd){
            padding-top:3.2rem;
            
            color:#555; }
            }
         }
        }
  
`


const ViewMoreButton = styled(motion.button)`
  display:flex;
  justify-content:center;
  align-items:center;
  width:calc(100%);
  height:calc(45px);
  margin-left:auto;
  margin-right:auto;
  border: 0px;
  border-bottom-width: 1px;
  border-color:#AAA;
  border-style:solid;
  background-color: rgba(0,0,0,0);

  font-family: transat-text, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.2rem;
  letter-spacing: 0.15rem;
  color:#666;
  outline:0px;
  margin-top:45px;
  margin-bottom:15px;
`

const Bar = styled(motion.div)`
  display:flex;
  justify-content:center;
  align-items:center;
  width:calc(100%); max-width:950px;
  height:calc(0px);
  /* appearance: none;   */
  margin-left:0;
  margin-right:0;
  margin-top:45px;
  border: 0px;
  border-bottom-width: 1px;
  border-color:#AAA;
  border-style:solid;
  background-color: rgba(0,0,0,0);
`


const variants = {
  initial:{
    height:"80%",
    opacity:0
  },
  animate:{
    height:"100%",
    opacity:1

  },
  exit:{
    opacity:0,
    height:"80%",

  }
}

const AboutCompany = () => {
  const x = useSpring(0)
  const widthBar = useTransform(x,[-1060,0],[350,0])


  const statusCtx = useContext(menuStatusContext);
  const ref = useInViewEffect(([entry], observer) => {
    if (entry.isIntersecting) {
        statusCtx.setIsName(entry.target.id)}
  }, { threshold: 0.4 })

  const [isVisible,setVisible]=useState(false);

    return (
      <ContainerCard ref={ref} id="company">
 
        <Title>COMPANY</Title>
        <TitleSub>会社概要</TitleSub>
           <SvgContainer>
              <SvgBox  alt="face"
              xmlns="http://www.w3.org/2000/svg"
              // variants={arrow}
              exit="exit"
              >
              <Logo/>
              </SvgBox>
              
          </SvgContainer>
          <JPName>株式会社トラン・ザイン</JPName>

          <div>
            <PARAGRAPH>
            未来の生活や新しい社会のイメージを3DCGを利用したビジュアライズやデジタルアプリケーションによるプロトタイピングを通して具現化をサポートします。<br/>
            </PARAGRAPH>
            </div>
            <ViewMoreButton onClick={()=>setVisible(true)}>{isVisible?"":"VIEW MORE INFO"}</ViewMoreButton>
         <AnimatePresence>
          {isVisible && (
              <>
          <Record layout variants={variants} initial="initial" animate="animate" exit="exit">

            <motion.div>
            <motion.ul >
            {
              [['社名',"株式会社トラン・ザイン / TranZainInc."], 
              ['設立',"2020年2月"], 
              ['資本金',"3,000,000円"],
              ['代表取締役',"大塚聡"],
              ['所在地',"〒178-0061","東京都練馬区大泉学園町5-14-33"],
              ['URL',"https://www.tranzain.com/"],
              ['事業内容',"デジタル領域を基点としたデザイン制作",
              "3DCGモデリング/レンダリング/アニメーション",
              "自社製品・サービスの企画・デザイン・開発・製造・販売"],
              ].map((text, index) => (
                <div>
                <li>{text[0]}</li>
                {text.length===2&& (<li>{text[1]}</li>)}
                {text.length===3&& (<li>{text[1]}<br/>{text[2]}</li>)}
                {text.length===4&& (<li>{text[1]}<br/>{text[2]}<br/>{text[3]}</li>)}
                </div>
            ))}
            </motion.ul>
            </motion.div>
          </Record>
          <Bar/></>)}
          </AnimatePresence>
         
      </ContainerCard>
    )
};

export default AboutCompany



