import React ,{useContext}from 'react'
import { motion, AnimatePresence } from "framer-motion"
import styled from 'styled-components'
import { menuContext } from '../../contexts/menuContext';
import { NavHashLink as NavLink } from 'react-router-hash-link';


//style
const ModalBackground = styled(motion.div)`
width: 100vw;
height: 100vh;
position:fixed;
display:flex;
align-items:center;
justify-content:center;
margin:0px;
padding:0px;
background-color:rgba(5,5,15,0.95);
backdrop-filter: blur(12px);
`

const MenuList = styled(motion.div)`
width: 100%;
/* height: 20%; */
/* margin-bottom:30px; */
/* background-color:rgba(5,5,15,0.8); */
  padding-top:2rem;
    padding-bottom:2rem;
  /* justify-content:flex-end; */
  
`

const NavHash  = styled(NavLink)`
  color: white;
  text-decoration:none;

  font-family: futura-pt, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size:2rem;
  letter-spacing: 1.2rem;
  margin-right: -1.2rem;
  font-feature-settings: "palt";
  /* text-shadow: hsla(0,90%,40%,0.5) 0px -5px,hsla(50,90%,40%,0.5) 0px -10px; */
  /* padding-top:3rem; */

`;

const variantsBG ={
    initial:{
            opacity:0,
            },
    animate:{
            opacity:1,
            transition: {
              when: "beforeChildren",
              delayChildren: 0.5,
              duration:0.6}  
          },
    exit:{
            opacity:0,
            transition: {
              when: "afterChildren",
              delayChildren: 0.5,
              duration:0.6}  
          },
}

const container = {
    hidden: { 
            x: 0,
            opacity: 0 
            },
    show: {
      opacity: 1,
      transition: {
        duration:0.2,
        delay:0.1,
        type: "tween",
        staggerChildren:0.1
      }
    },
    exit:{
      opacity:0
    },
  }
  
  const item = {
    exit:{
      opacity:0
    },
    hover:{
      scale:1.1
    },
    tap:{
      scale:0.92
    },
    hidden:{
      y: -30,
      opacity:0
    },
    show:{
      y: 0,
      opacity:1.0,
      transition: {
        type: "tween",
      }
    },
    exitBeforeEnter:true
  }

const MenuModal = () => {
    const ctx = useContext(menuContext);
    const Switch = () => {
        ctx.setIsOpen(!ctx.isOpen)
      };

      const renderSwitch=(param)=>{
        switch(param) {
          case 'HOME':
            return  <NavHash smooth to="/#home">{param}</NavHash> ;
          case 'COMPANY':
            return  <NavHash smooth to="/#company">{param}</NavHash> ;
          // case 'PORTFOLIO':
          //   return  <NavHash smooth to="/portfolio#portfolio">{param}</NavHash> ;
          case 'CONTACT':
              return  <NavHash smooth to="/#contact" >{param}</NavHash> ;
          default:
            return ;
        }
      }
     
    return (
        <AnimatePresence >
            {ctx.isOpen && (
              //  {true && (
            <ModalBackground
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={variantsBG}
                    >
                    <motion.div>
                      <motion.ul 
                      PositionLayout
                      variants={container}
                      initial="hidden"
                      animate="show"
                      exit="exit">
                        {[['HOME'], 
                        ['COMPANY'], 
                        // ['PORTFOLIO'], 
                        ['CONTACT']].map((text, index) => (
                        <motion.li key={index} variants={item} whileHover="hover" whileTap="tap" onClick={()=>Switch()}>
                          <MenuList>
                          {renderSwitch(text[0])}
                          </MenuList>
                        </motion.li>
                        ))} 
                      </motion.ul>
                    </motion.div>
            </ModalBackground>
            )}
        </AnimatePresence>
    )
}

export default MenuModal