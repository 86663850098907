import React ,{useState,useContext,useEffect}from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion"
import { useInViewEffect} from 'react-hook-inview'
import { menuStatusContext } from '../contexts/menuContext';
import CopyToClipBoard from 'react-copy-to-clipboard';
import useClipboard from "react-use-clipboard";

import AssignmentIcon  from '@material-ui/icons/Assignment';
import IconButton      from '@material-ui/core/IconButton';
import Tooltip         from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';

const Container = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:100vw;
  height:100vh;
  background:#F6F6F6;
  `
  const Area = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:calc(100vw - 32px);
    /* height:calc(100vh - 32px); */
    max-width:800px;
    /* background:yellow; */
    border-radius:16px;
    `

const Title = styled.h1`
font-family: transat-text, sans-serif;
font-weight: 400;
font-style: normal;
font-size: 2.6rem;
letter-spacing: 0.6rem;
/* text-indent: 1rem; */
/* font-feature-settings:palt; */
color: #222;
border-style:none;
outline:0px;
margin-top:75px;
/* text-align:left; */
/* margin-top:5rem; */
`
const TitleSub = styled.h1`
font-family: 游ゴシック, sans-serif;
font-weight: 800;
font-style: normal;
font-size: 1.2rem;
letter-spacing: 0.2rem;
/* text-indent: 1rem; */
/* font-feature-settings:palt; */
color: #555;
border-style:none;
outline:0px;
/* text-align:left; */
margin-top:10px;
margin-bottom:75px;
/* margin-top:5rem; */
`

const Paragraph = styled.p`
  /* font-family: 游ゴシック, sans-serif; */
  font-family:"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体" sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  /* font-size: 12px; */
  line-height:2.1rem;
  text-align:justify;
  font-feature-settings:palt;
  letter-spacing: 0.15rem;
  color: #333;
  border-style:none;
  outline:0px;
  padding-left:16px;
  padding-right:16px;
  margin-top:3.0rem;
  /* margin-bottom:3.4rem; */
  margin-bottom:8.0rem;
  /* word-break:keep-all; */
  /* @media screen and (min-width: 1280px) {
  font-size: 1rem;
  word-break:keep-all;
  } */
`

const MailButton = styled(motion.button)`
  display:flex;
  justify-content:center;
  align-items:center;
  width:calc(100% - 32px);
  max-width:400px;
  height:calc(45px);
  appearance: none;  
  margin-left:auto;
  margin-right:auto;
  border: 0;
  border-color:#AAA;
  /* border-width:2px; */
  border-style:solid;
  border-radius:45px;
  background: #EAEAEA;
  /* background: white; */
  /* color: #fff; */

  font-family: transat-text, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  color: black;
  outline:0px;
  /* box-shadow: hsla(0,90%,40%,0.5) 0px -5px,hsla(250,100%,70%,0.2) 0px 10px; */
 
 /* :hover {
  background: #000000;
  color: white;
} */
/* :focus {
  outline: none;
  box-shadow: 0 0 0 1px #000000;
} */
`

const buttonVariants = {
  hover:{
    scale:1,
  },
  initial:{
    scale:1,
    backgroundColor: "#EAEAEA",
    color: "black"
  },
  tap:{
    scale:0.97,
    backgroundColor:"#AAA",
    color:"white"
  },
}

const HomeContact = () => {
  
const ct = String.fromCharCode(99,111,110,116,97,99,116,64);
const ma = String.fromCharCode(116,114,97,110,122,97,105,110,46,99,111,109);


  // アドレスコピー
  const [open,   setOpen]   = useState(true);
  const [input,   setInput]   = useState("");
  const [isCopied, setCopied] = useClipboard(input, {successDuration: 1000});

  // MenuStatusChange
  const statusCtx = useContext(menuStatusContext);
  const ref = useInViewEffect(([entry], observer) => {
    if (entry.isIntersecting) {statusCtx.setIsName(entry.target.id)}
  }, { threshold: 0.4 })
  const TEXTS = "CLICK & COPY EMAIL ADDRESS"

  const onAlert = () => window.alert("クリップボードにメールアドレスをコピーしました");

  useEffect(() => {
    if(open){
      setInput(ct+ma)
    }
    else{setInput("")}
  }, [open]) 



    return (
        <Container ref={ref} id="contact">
          <Area>
            <Title>CONTACT</Title>   
            <TitleSub>お問い合わせ</TitleSub>   
              <Paragraph>
              制作のご依頼・ご相談など、お気軽にお問い合わせください。<br/>
              ※メールアドレスは下のボタンからコピーをお願い致します。
              {/* 折り返しのご連絡は担当者から翌営業日中にさせていただきます。 */}
              </Paragraph>

                {/* メールアドレス */}
                <CopyToClipBoard text={input}>
                    <MailButton
                    variants = {buttonVariants}
                    initial = "initial"
                    whileHover = "hover"
                    whileTap = "tap"
                    onClick={onAlert}
                    // transition={{ ease: "easeOut", duration: 2 }}
                    >{TEXTS} </MailButton>
                </CopyToClipBoard>
              {/* <MailButton onClick={()=>setCopied()}>Copy toON {isCopied+""}</MailButton>  */}
            </Area>
        </Container>
        
    )
}

export default HomeContact
