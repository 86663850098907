import { createContext, useCallback, useState } from 'react';

// set context type
type MenuContext = {
  dark: boolean;
  isVisible: boolean;
  isOpen: boolean;
  setIsDark: (isDark: boolean) => void;
  setIsVisible: (isVisible: boolean) => void;
  setIsOpen: (isOpen: boolean) => void;
};

type MenuStatusContext = {
  isName: string;
  setIsName: (isName: string) => void;
};

// context default value
const defaultContext: MenuContext = {
  dark: false,
  isVisible: false,
  isOpen: false,
  // 初期値を作成するが、eslintに引っかかる
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsDark: () => {},
  setIsVisible: () => {},
  setIsOpen: () => {}
};

const statusContext: MenuStatusContext = {
  isName: "",
  setIsName: () => {}
};



// context object
export const menuContext = createContext<MenuContext>(defaultContext);

export const menuStatusContext = createContext<MenuStatusContext>(statusContext);


// custom Hook
export const useDark = (): MenuContext => {
  // state名はThemeContext typeのプロパティに合わせる。
  const [dark, setDark] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  // 関数名はThemeContext typeのプロパティに合わせる。
  const setIsDark = useCallback((current: boolean): void => {
    setDark(current);
  }, []);
  const setIsVisible = useCallback((currentVis: boolean): void => {
    setVisible(currentVis);
  }, []);
  const setIsOpen = useCallback((currentVis: boolean): void => {
    setOpen(currentVis);
  }, []);
  return {
    dark,
    isVisible,
    isOpen,
    setIsDark,
    setIsVisible,
    setIsOpen
  };
};

export const useStatus = (): MenuStatusContext => {
  // state名はThemeContext typeのプロパティに合わせる。
  const [isName, setName] = useState("COMPANY");
  // 関数名はThemeContext typeのプロパティに合わせる。
  const setIsName = useCallback((currentVis: string): void => {
    setName(currentVis);
  }, []);
  return {
    isName,
    setIsName
  };
};


