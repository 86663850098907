import React,{useContext} from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion"

import { useInViewEffect} from 'react-hook-inview'
import { menuStatusContext } from '../contexts/menuContext';
import About from './About'
import HomeContact from './HomeContact'

const pageVariants = {
  initial:{
    opacity:0,
    // y:"-100vw"
    y:"-2rem"
  },
  animate:{
    opacity:1,
    y:0
  },
  exit:{
    opacity:0,
    // y:"-100vw"
    y:"2rem"
  }
}

const pageTransition = {
  type:"spring",
  stiffness:25,
 }

const TopLayout = styled(motion.div)`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
background:#F6F6F6;
width:100vw;
min-height:100vh;
margin:0px;
padding:0px;
`
const Box = styled.div`
display:flex;
flex:row;
align-items:center;
justify-content:center;
background:#F6F6F6;
width:100vw;
height:100vh;
margin:0px;
padding:0px;
`

const Logo = styled.h1`
color: #111;
font-family: futura-pt, sans-serif;
font-weight: 600;
font-style: normal;
font-size:2.4rem;
text-align: center;
letter-spacing: 0.1rem;
border-style:none;
outline:0px;
margin:0px;
`




const Home = () => {
  const statusCtx = useContext(menuStatusContext);
  const ref = useInViewEffect(([entry]) => {
    if (entry.isIntersecting) {statusCtx.setIsName(entry.target.id)}
  }, { threshold: 0.4 })

    return (
      <TopLayout
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        variants={pageVariants}
        transition={pageTransition}>

          <Box ref={ref} id="home">
            <Logo>TranZainInc.</Logo>
          </Box>

          <About/>

           <HomeContact/>
         
      </TopLayout>
    )
}

export default Home


