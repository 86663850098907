import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import MenuSvg from './MenuSvg';
import MenuModal from './MenuModal';

const ModalBackground = styled(motion.div)`
position:fixed;
top:0px;
z-index:100;
`
const MenuPos = styled(motion.div)`
    position:fixed;
    top:9px;
    right:9.5px;
    border-radius: 50px;
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    z-index:1000;
  @media screen and (min-width: 1280px) {
  top:30px;
  right:calc( 0vw + 30px );
    }
`;

const Toggle = () => {
    return (
      <ModalBackground> 
          <MenuPos>
             <MenuSvg />
          </MenuPos> 
        <MenuModal/>
      </ModalBackground>
    )
}

export default Toggle





