import React from 'react'
import { motion } from "framer-motion"
import styled from 'styled-components'
import AboutCompany from "./AboutCompany"

const pageVariants = {
  in:{
    opacity:1,
    y:0
  },
  out:{
    opacity:0,
    y:"-100vw"
  }
}

const pageTransition = {
  type:"spring",
  stiffness:10,
 }

 const Container=styled(motion.div)`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 width:100vw;
 min-height:100vh;
 background:#F6F6F6;

 `

const About = () => {

    return (
  <Container  
    // initial={"out"}
    // animate={"in"}
    // exit={"out"}
    // variants={pageVariants}
    // transition={pageTransition}
    >
    <AboutCompany/>
     
    </Container>
    )
};

export default About



